<template>
  <title>Disclaimer | Dividenduitkering</title>
  <div class="col-12 d-md-none cmtm">
    <div class="du-lottie-player">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <div class="col-12 cptm">
    <h2 class="largeTextXl mb-3">Disclaimer</h2>
  </div>
  <div class="col-md-6 col-12 container-content mb-3"
       :class="{'font-white': isDarkPage}">
    <div class="cmbm">
      <div class="cp-p0">De publicaties op deze website zijn bedoeld om algemene informatie te geven. Indien er sprake is van uitleg of advies dan heeft dit een algemeen karakter en is de uitleg of het advies niet gericht op een specifieke situatie of een probleem. Wij adviseren daarom altijd contact op te nemen met één van onze adviseurs voordat u op basis van de informatie, de uitleg of het advies besluiten neemt of activiteiten ontplooit.</div>
      <div class="cp-p0">De informatie is op het moment van publicatie zorgvuldig getoetst. Wij kunnen u echter geen garantie geven dat alle informatie volkomen juist of actueel is en/of blijft.</div>
      <div class="cp-p0">Het gebruik van de informatie is altijd volledig voor uw eigen risico en verantwoordelijkheid. Wij kunnen hiervoor geen aansprakelijkheid aanvaarden.</div>
      <div class="cp-p0">Wij streven naar optimale beschikbaarheid van onze website. In die situaties waarin de website op een bepaald moment, onder welke omstandigheden dan ook, niet beschikbaar is, kunnen wij geen aansprakelijkheid aanvaarden voor eventuele gevolgen daarvan.</div>
    </div>
  </div>
  <div class="col-md-6 d-md-flex d-none du-lottie-column mb-3">
    <div class="du-lottie-player h-100">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <div class="col-12 container-content mb-3" :class="{'font-white': isDarkPage}">
    <div class="cmbm">
      <p class="cp-h1">Websites van derden</p>
      <p class="cp-p0">Onze privacyverklaring is niet van toepassing op websites van derden die door middel van links met onze website zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met uw persoonsgegevens omgaan. Wij raden u aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te maken. De privacyverklaring van websites van derden vindt u hieronder:</p>
      <ul class="cp-p0">
        <li><a href="https://burofoss.nl/privacy-statement" class="cp-a1" target="_blank">Buro Foss</a></li>
        <li><a href="https://lyfter.nl/kleine-letters/privacyverklaring" class="cp-a1" target="_blank">Lyfter</a></li>
        <li><a href="https://policies.google.com/privacy?hl=nl" class="cp-a1" target="_blank">Google</a></li>
        <li><a href="https://www.facebook.com/privacy/policy" class="cp-a1" target="_blank">Meta</a></li>
        <li><a href="https://www.linkedin.com/legal/privacy-policy" class="cp-a1" target="_blank">LinkedIn</a></li>
        <li><a href="https://x.com/en/privacy" class="cp-a1" target="_blank">X (Twitter)</a></li>
        <li><a href="https://spotler.com/privacy-statement" class="cp-a1" target="_blank">Spotler</a></li>
        <li><a href="https://www.microsoft.com/nl-nl/privacy/privacystatement" class="cp-a1" target="_blank">Microsoft Clarity</a></li>
        <li><a href="https://www.springbokagency.com/privacy-policy" class="cp-a1" target="_blank">Springbok Agency</a></li>
        <li><a href="https://automattic.com/privacy/" class="cp-a1" target="_blank">WordPress</a></li>
        <li><a href="https://policies.google.com/privacy" class="cp-a1" target="_blank">YouTube</a></li>
        <li><a href="https://zapier.com/legal/data-privacy" class="cp-a1" target="_blank">Zapier</a></li>
      </ul>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>
<script>
import WindowChecker from "@/components/WindowChecker.vue";
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {useTheme} from "@/components/utils/theme";

export default {
  name: `Disclaimer`,
  components: {WindowChecker,Navigation, Header, Footer},
  setup(){
    const { isDarkPage } = useTheme()
    return {
      isDarkPage,
    }
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
}
</script>
<style scoped>
  .cp-h1{
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .cp-h2{
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .cp-p0{
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .cp-p1{
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  .cp-a1{
    font-weight: bold;
    text-decoration: underline;
  }

  .table{
    &>:not(caption)>*>*{
      background:transparent;
      color: white;

    }
    width: calc(100% - 2rem);
  }
</style>
