import {createRouter as createVueRouter, createWebHashHistory, createWebHistory, Router} from 'vue-router'
import Welcome from '@/views/Welcome.vue'
import HowItWorks from "@/views/HowItWorks.vue";
import StartRequest from "@/views/StartRequest.vue";
import QuestionView from "@/views/QuestionView.vue";
import RequestForm from "@/views/RequestForm.vue";
import Result from "@/views/Result.vue";
import RequestOverview from "@/views/RequestOverview.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Payment from "@/views/Payment.vue";
import DiscountOverview from "@/views/DiscountOverview.vue";

// @ts-ignore
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from 'vue';
import CookiePolicy from "@/views/CookiePolicy.vue";
import Disclaimer from "@/views/Disclaimer.vue";
export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "Welcome",
        component: Welcome,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/hoe-werkt-het",
        name: "HowItWorks",
        component: HowItWorks,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/cookieverklaring/",
        name: "CookiePolicy",
        component: CookiePolicy,
        meta: {
          needsAuth: false,
        }
      },
      {
        path: "/disclaimer/",
        name: "Disclaimer",
        component: Disclaimer,
        meta: {
          needsAuth: false,
        }
      },
      {
        path: "/start-aanvraag",
        name: "StartRequest",
        component: StartRequest,
        // beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/overzicht",
        name: "RequestView",
        component: RequestOverview,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/nieuwe-aanvraag",
        name: "New-Request",
        component: RequestForm,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/kortingscodes",
        name: "DiscountOverview",
        component: DiscountOverview,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/betaling/:paymentStatus",
        name: "Payment",
        component: Payment,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: 'ErrorView',
        component: PageNotFound,
        meta: {
          needsAuth: false,
        }
      },
      {
        path: "/vragenlijst/:id",
        name: "QuestionList",
        component: QuestionView,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: true,
        }
      },
    ],
    history: createWebHistory(),
  })
}
