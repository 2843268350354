<template>
  <title>Cookieverklaring | Dividenduitkering</title>
  <div class="col-12 d-md-none cmtm">
    <div class="du-lottie-player">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <div class="col-12 cptm">
    <h2 class="largeTextXl mb-3">Cookieverklaring</h2>
  </div>
  <div class="col-md-6 col-12 container-content mb-3"
       :class="{'font-white': isDarkPage}">
    <div class="cmbm">
      <p class="cp-h1">I. Algemeen</p>
      <p class="cp-h2">I.1 Moore DRV</p>
      <p class="cp-p1">Deze cookieverklaring is opgesteld door de openbare maatschap Moore DRV (hierna: “<b>Moore DRV</b>”). De contactgegevens van Moore DRV zijn:</p>
      <p class="cp-p1">
        Adres: Joulehof 10, 4622 RG  Bergen op Zoom <br>
        Telefoon: 0164-241850 <br>
        E-mail: <a class="cp-a1" href="mailto:info@moore-drv.nl">info@moore-drv.nl</a><br>
        KvK: 24492873
      </p>
    </div>
    <div class="cmbm">
      <p class="cp-h2">I.2 Websites</p>
      <p class="cp-p1">Deze cookieverklaring heeft betrekking op de cookies of vergelijkbare technologieën die door Moore DRV worden gebruikt op de volgende websites (hierna: websites):</p>
      <ul class="cp-p1">
        <li><a href="https://www.moore-drv.nl/" class="cp-a1" target="_blank">moore-drv.nl</a></li>
        <li><a href="https://www.werkenbijmooredrv.nl" class="cp-a1" target="_blank">werkenbijmooredrv.nl</a></li>
        <li><a href="https://crossminds.nl" class="cp-a1" target="_blank">crossminds.nl</a></li>
        <li><a href="https://redefine-it.eu" class="cp-a1" target="_blank">redefine-it.eu</a></li>
        <li><a href="https://zantboer.nl" class="cp-a1" target="_blank">zantboer.nl</a></li>
      </ul>
    </div>
  </div>
  <div class="col-md-6 d-md-flex d-none du-lottie-column mb-3">
    <div class="du-lottie-player h-100">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <div class="col-12 container-content mb-3" :class="{'font-white': isDarkPage}">
    <div class="cmbm">
      <p class="cp-h2">I.3 Informatieplicht</p>
      <p class="cp-p1">Op Moore DRV rust - als verwerkingsverantwoordelijke - de verplichting om de personen van wie zij persoonsgegevens verwerkt te informeren over het hoe en waarom van die verwerkingen. Aan deze informatieplicht voldoet Moore DRV door middel van deze privacyverklaring.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h2">I.4 Wijzigingen</p>
      <p class="cp-p1">Deze cookieverklaring is opgesteld in november 2023. Moore DRV kan deze verklaring in de toekomst aanpassen. Indien Moore DRV deze verklaring aanpast zal de gewijzigde cookieverklaring op de websites worden gepubliceerd, met vermelding van de datum waarop de wijzigingen ingaan. Als sprake is van wijzigingen die één of meer betrokkenen in aanmerkelijke mate kunnen treffen, dan doet Moore DRV haar best om die betrokkenen hierover ook direct te informeren.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h1">II. Cookies</p>
      <p class="cp-h2">II.1 Definitie</p>
      <p class="cp-p1">Cookies zijn (onzichtbare) bestandjes die bij het bezoeken van een website automatisch informatie opslaan op het device (zoals een PC, tablet of smartphone) van de bezoeker. Dat gebeurt via de browser op het device. De browser kan deze informatie bij een volgend bezoek door de bezoeker aan de website terugsturen.</p>
      <p class="cp-p1">Daarnaast zijn er ook vergelijkbare technieken als die van cookies, welke technieken bij het bezoeken van de website gegevens van het device van de bezoeker kunnen uitlezen. Voor het leesgemak worden alle dergelijke technieken hier ‘cookie’ genoemd. De informatie die wordt verkregen over het gebruik van de website kan worden overgebracht naar servers van derden.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h2">II.2 Soorten cookies</p>
      <p class="cp-p1">Er zijn verschillende soorten cookies:</p>
      <ul class="cp-p1">
        <li>noodzakelijke cookies;</li>
        <li>voorkeurscookies;</li>
        <li>analytische cookies;</li>
        <li>social media cookies; en</li>
        <li>trackingcookies</li>
      </ul>
      <p class="cp-p1"><b>Noodzakelijke cookies</b></p>
      <p class="cp-p1">Noodzakelijke cookies zijn essentieel voor het goed functioneren van de website. Deze categorie bestaat alleen uit cookies die basisfuncties en beveiligingsfuncties van de website garanderen. Ze worden onder andere gebruikt voor het geautomatiseerd invullen van formulieren en voor het goed laten werken van een chatfunctie</p>
      <p class="cp-p1"><b>Voorkeurscookies</b></p>
      <p class="cp-p1">Voorkeurscookies zorgen voor een goede gebruikerservaring door informatie te onthouden over de door gebruiker gewenste structuur en gedrag op de website. Denk hierbij aan zaken als de taalinstelling, de regio waar de websitebezoeker zich bevindt en het onthouden van een zoekterm waarop wordt gezocht binnen de website.</p>
      <p class="cp-p1"><b>Analytische cookies</b></p>
      <p class="cp-p1">Analytische cookies verwerken statistische gegevens over het gebruik van de website. Dit is praktisch en ook noodzakelijk om websites verder te kunnen blijven ontwikkelen en verbeteren. Deze cookies maken het mogelijk om op basis van het surfgedrag te achterhalen welke informatie bezoekers nuttig of juist overbodig vinden. Analytische cookies houden bijvoorbeeld bij hoe vaak een website wordt bezocht of hoe vaak een artikel op een nieuwssite wordt gelezen. Ze kunnen ook bijhouden vanuit welke regio de websitebezoekers voornamelijk komen, alsmede of de mobiele versie van de website populairder is dan de desktopversie.</p>
      <p class="cp-p1"><b>Social media cookies</b></p>
      <p class="cp-p1">Sociale media cookies zijn een nieuw geïntroduceerde categorie cookies die worden ingesteld door sociale-mediaplatforms van derden die in een website zijn geïntegreerd. Cookies van sociale netwerksites zoals Twitter, Facebook, Instagram en LinkedIn vallen bijvoorbeeld onder deze social media cookies. Deze cookies maken het mogelijk om op de website geplaatste video’s en/of content van dat sociale netwerk te bekijken en om een meer persoonlijke websitebezoek te realiseren. Social media cookies zijn sterk verwant met tracking cookies.</p>
      <p class="cp-p1"><b>Marketing cookies</b></p>
      <p class="cp-p1">Marketing cookies, zijn cookies die binnen een website of over verschillende websites gebruikt worden om surf- en zoekgedrag van de websitebezoekers vast te leggen. Kort gezegd gaat het dan om het bijhouden van surf- en zoekgedrag met het oog op marketingactiviteiten. Deze tracking cookies zorgen ervoor dat bijvoorbeeld online advertenties beter aansluiten bij de interesse van de websitebezoeker omdat inzicht bestaat in de wensen en voorkeuren van die bezoeker.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h2">II.3 First party versus third party cookies</p>
      <p class="cp-p1">Daarnaast kunnen de cookies die gebruikt worden op onze website onderscheiden worden in first-party cookies en third-party cookies.</p>
      <p class="cp-p1"><b>First party</b></p>
      <p class="cp-p1">First-party cookies worden geplaatst door Moore DRV zelf. Bijvoorbeeld de cookies waarmee surfgedrag over de bezoekers in Google Analytics wordt verwerkt. Moore DRV maakt naast een eigen cookie ook gebruik van cookies van Google Analytics en Microsoft Clarity. Deze cookies geven inzicht in hoe onze website gebruikt wordt, om rapporten over de website aan ons te kunnen verstrekken en om ons informatie over de effectiviteit van onze campagnes te kunnen bieden. Deze partijen handelen in de opdracht van Moore DRV en wij bepalen wat er met informatie gebeurt die de cookies verzamelen. Dat doen wij op de volgende wijze:</p>
      <ul class="cp-p1">
        <li>Moore DRV heeft een verwerkersomovereenkomst gesloten met Google en Microsoft, waarin afspraken zijn gemaakt over het gebruik van uw gegevens;</li>
        <li>Moore DRV heeft maatregelen getroffen die ervoor zorgen dat slechts een zeer beperkt deel van uw IP-adres aan Google en Microsoft wordt verstrekt;</li>
        <li>Moore DRV heeft maatregelen getroffen die ervoor zorgen dat uw gegevens niet worden verwerkt door Google en Microsoft voor eigen doelen en dat ze niet mogen worden gebruikt om u gerichte advertenties te tonen;</li>
        <li>Moore DRV heeft maatregelen getroffen die ervoor zorgen dat Google en Microsoft niet de mogelijkheid hebben om uw surfgedrag van verschillende apparaten en meerdere sessies te combineren;</li>
        <li>Moore DRV maakt bij gebruik van andere diensten van Google of Microsoft geen gebruik van de gegevens die de analytische cookies van Google en Microsoft verzamelen.</li>
      </ul>
      <p class="cp-p1">Moore DRV maakt ook gebruik van een eigen cookie (Springbok) om webstatistieken te kunnen bijhouden van de personen die onze website bezoeken.</p>
      <p class="cp-p1"><b>Third party</b></p>
      <p class="cp-p1">Third-party cookies worden geplaatst op onze website door een bedrijf dat niet direct betrokken is bij Moore DRV. Bedrijven zoals Vimeo, Google, LinkedIn en TikTok vallen hieronder. Vaak zijn dit zogenaamde social media en/of marketing cookies bedoeld om interactie met sociale netwerken mogelijk te maken, interesses op te slaan en klikgedrag te volgen. Moore DRV heeft geen controle over die derde partijen en hoe zij met uw gegevens omgaan. In dat kader is in onderstaande overzichten een link naar de betreffende privacyverklaringen van die derde partijen opgenomen.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h1">III. Moore DRV</p>
      <p class="cp-h2">III.1 Noodzakelijke cookies</p>
      <p class="cp-p1">Moore DRV maakt gebruik van de volgende noodzakelijke cookies:</p>
      <div class="table-container cp-p1">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Cookie</th>
            <th>van</th>
            <th>doeleinde</th>
            <th>partij</th>
            <th>duur</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>uc_settings and/or ucString</td>
            <td>Usercentrics <br>Consent <br>Management <br>Platform</td>
            <td>Dit bevat de ControllerID en SettingsID, de taal, instellingenversie en services met hun toestemmingsgeschiedenis.</td>
            <td>third-party</td>
            <td></td>
          </tr>
          <tr>
            <td>ucData (optional)</td>
            <td>Usercentrics <br>Consent <br>Management <br>Platform</td>
            <td>Dit bevat informatie over de Google-toestemmingsmodus.</td>
            <td>third-party</td>
            <td></td>
          </tr>
          <tr>
            <td>__cf_bm</td>
            <td>Cloudflare</td>
            <td>Dit wordt gebruikt voor de botbeschermingsoplossing van Cloudflare.</td>
            <td>third-party</td>
            <td>30 minuten</td>
          </tr>
          <tr>
            <td>cf_clearance</td>
            <td>Cloudflare</td>
            <td>Deze cookie wordt gebruikt om het doorgegeven bewijs van uitdaging op te slaan. Het wordt gebruikt om geen uitdaging meer uit te geven, indien aanwezig. Het is vereist om een oorsprongsserver te bereiken.</td>
            <td>third-party</td>
            <td>30 minuten</td>
          </tr>
          <tr>
            <td>_cfuvid</td>
            <td>Cloudflare</td>
            <td>Deze cookie wordt gebruikt voor snelheidsbeperkende regels.</td>
            <td>third-party</td>
            <td>1 week</td>
          </tr>
          <tr>
            <td>cf_chi_cc_XXX, cf_chl_rc_i, cf_chl_rc_ni</td>
            <td>Cloudflare</td>
            <td>De cookie wordt door Cloudflare gebruikt voor het uitvoeren van challenges. Het wordt niet gebruikt voor tracking of valt buiten het bereik van de challenge.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>_cflb</td>
            <td>Cloudflare</td>
            <td>Dit wordt gebruikt om een eindgebruiker terug te sturen naar dezelfde klantoorsprong voor een specifieke, door de klant geconfigureerde periode.</td>
            <td>third-party</td>
            <td>1 dag</td>
          </tr>
          <tr>
            <td></td>
            <td>Google Fonts</td>
            <td>Dit is een verzameling lettertypen voor commercieel en persoonlijk gebruik.</td>
            <td>third-party</td>
            <td></td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>Google Tag Manager</td>
            <td>Dit is een tagbeheersysteem. Via Google Tag Manager kunnen tags centraal worden geïntegreerd via een gebruikersinterface. Tags zijn kleine stukjes code die activiteiten kunnen volgen. Scriptcodes van andere tools zijn geïntegreerd via de Google Tag Manager. Met de Tag Manager kunt u bepalen wanneer een bepaalde tag wordt geactiveerd.</td>
            <td>third-party</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>BootstrapCDN</td>
            <td>Dit is een openbaar content delivery netwerk.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td></td>
            <td>Gravatar</td>
            <td>Dit is een service die avatars biedt aan internetgebruikers die opmerkingen achterlaten op webpagina's.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          </tbody>
        </table>
      </div>
      <p class="cp-p1">Voor het gebruik van deze cookies hoeft Moore DRV u geen toestemming te vragen.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h2">III.2 Analytische cookies</p>
      <p class="cp-p1">Moore DRV maakt gebruik van de volgende analytische cookies:</p>
      <div class="table-container cp-p1">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Cookie</th>
            <th>van</th>
            <th>doeleinde</th>
            <th>partij</th>
            <th>duur</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>__utmb</td>
            <td>Google analytics</td>
            <td>Deze cookie wordt gebruikt om het tijdstip van het bezoek bij te houden.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Google analytics</td>
            <td>Deze cookie wordt gebruikt om onderscheid te maken tussen gebruikers.</td>
            <td>third-party</td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Google analytics</td>
            <td>Deze cookie wordt gebruikt om de gebruiker te identificeren.</td>
            <td>third-party</td>
            <td>1 dag</td>
          </tr>
          <tr>
            <td>__utma</td>
            <td>Google analytics</td>
            <td>Deze cookie wordt gebruikt om de tijd en datum van het eerste bezoek, het totale aantal bezoeken en de starttijd van het huidige bezoek vast te leggen.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>__utmz</td>
            <td>Google analytics</td>
            <td>Deze cookie wordt gebruikt om bij te houden waar de bezoeker vandaan komt.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om gepersonaliseerde advertenties te tonen.</td>
            <td>third-party</td>
            <td>1 jaar</td>
          </tr>
          <tr>
            <td>CONSENT</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om de toestemmingskeuzes van de gebruiker op te slaan.</td>
            <td>third-party</td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>__utmt</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om de aanvraagsnelheid te vertragen.</td>
            <td>third-party</td>
            <td>10 minuten</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om verzoeken van bots te lezen en te filteren.</td>
            <td>third-party</td>
            <td>1 minuut</td>
          </tr>
          <tr>
            <td>__utmc</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om de tijd van het bezoek op te slaan.</td>
            <td>third-party</td>
            <td>30 minuten</td>
          </tr>
          <tr>
            <td>FPID</td>
            <td>Google analytics</td>
            <td>Dit wordt gebruikt om een waarde op te slaan die wordt gebruikt voor het instellen van de client-ID in het verzoek aan de servers van Google.</td>
            <td>third-party</td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>FPLC</td>
            <td>Google analytics</td>
            <td>Hiermee wordt een uniek ID geregistreerd, waarmee statistische gegevens worden gegenereerd over hoe de bezoeker de website gebruikt.</td>
            <td>third-party</td>
            <td>20 uur</td>
          </tr>
          <tr>
            <td>_clck</td>
            <td>Microsoft Clarity</td>
            <td>Dit behoudt de Clarity User ID en voorkeuren, is uniek voor die site en wordt toegeschreven aan dezelfde user ID.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>_clsk</td>
            <td>Microsoft Clarity</td>
            <td>Verbindt meerdere paginaweergaven door een gebruiker tot één enkele Clarity-sessieopname.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>CLID</td>
            <td>Microsoft Clarity</td>
            <td>Identificeert de eerste keer dat Clarity deze gebruiker zag op een site die Clarity gebruikte.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>ANONCHK</td>
            <td>Microsoft Clarity</td>
            <td>Geeft aan of MUID wordt overgedragen naar ANID, een cookie die wordt gebruikt voor advertenties. Clarity gebruikt geen ANID en daarom staat deze altijd op 0.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>MR</td>
            <td>Microsoft Clarity</td>
            <td>Geeft aan of MUID moet worden vernieuwd.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>MUID</td>
            <td>Microsoft Clarity</td>
            <td>Identificeert unieke webbrowsers die Microsoft-sites bezoeken. Deze cookies worden gebruikt voor advertenties, site-analyse en andere operationele doeleinden.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>SM</td>
            <td>Microsoft Clarity</td>
            <td>Gebruikt bij het synchroniseren van de MUID over Microsoft-domeinen.</td>
            <td>third-party</td>
            <td>sessie</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Google analytics 4</td>
            <td>Wordt gebruikt om gebruikers te onderscheiden.</td>
            <td>third-party</td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>_ga_#</td>
            <td>Google analytics 4</td>
            <td>Wordt gebruikt om de sessiestatus aan te houden.</td>
            <td>third-party</td>
            <td>2 jaar</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="cmbm">
      <p class="cp-h1">IV. In- en uitschakelen cookies</p>
      <p class="cp-p0">In uw browser kunt u instellen dat cookies alleen worden geactiveerd, wanneer u daar mee instemt. Raadpleeg hiervoor de handleiding van uw browser. Uw instemming is alleen niet nodig voor de noodzakelijke cookies. Deze cookies zijn noodzakelijk om de website te laten functioneren. Let op: onze websites werken mogelijk niet optimaal als u cookies uitschakelt.</p>
      <p class="cp-p0">Als u op enig moment één of meer cookies uitschakelt op een van onze websites, zal Moore DRV in dat geval direct stoppen met het verzamelen en verwerken van nieuwe informatie die die cookie verzamelt en opslaat. Moore DRV kan echter nog steeds de informatie gebruiken die is verzameld door die cookie voordat u de betreffende cookie heeft uitgeschakeld.</p>
    </div>
    <div class="cmbm">
      <p class="cp-h1">V. Verwijderen van cookies</p>
      <p class="cp-p0">Veel cookies hebben een bepaalde gebruiksduur. Als een gebruiksduur is ingesteld, wordt de cookie automatisch verwijderd wanneer die gebruiksduur verstrijkt. U kunt er - zoals aangegeven - ook voor kiezen een cookie handmatig uit te schakelen voordat de gebruiksduur is verstreken.</p>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>
<script>
import WindowChecker from "@/components/WindowChecker.vue";
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {useTheme} from "@/components/utils/theme";

export default {
  name: `CookiePolicy`,
  components: {WindowChecker,Navigation, Header, Footer},
  setup(){
    const { isDarkPage } = useTheme()
    return {
      isDarkPage,
    }
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
}
</script>
<style scoped>
  .cp-h1{
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .cp-h2{
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .cp-p0{
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .cp-p1{
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  .cp-a1{
    font-weight: bold;
    text-decoration: underline;
  }

  .table{
    &>:not(caption)>*>*{
      background:transparent;
      color: white;

    }
    width: 100%;
  }

  .table-container{
    width: calc(100% - 4rem);
    margin-right: 2rem;
  }

  @media only screen and (max-width: 768px) {
    .table-container{
      overflow-x: scroll;
    }
  }
</style>
